@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Hind:wght@300;400;500;600;700&display=swap');

/* Theme colors */
:root {
  --footer-height: 50px;
  --purple: #8722ED;
}

* {
  box-sizing: border-box;
  font-family: 'Hind', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

footer {
  height: var(--footer-height);
  padding: 15px;
  text-align: center;
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
}

form label, form input, form select {
  margin-top: 15px;
  width: 100%;
}  

h1 {
  padding: 0;
  font-size: 2em;
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Cardo', serif;
  text-align: center;
}  

input,
select {
  padding: 15px;
}  

main {
  min-height: calc(85vh - var(--footer-height));
  padding: 15px;
}  

thead {
  border-bottom: 2px solid var(--purple);
}  

.accounts {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 15px;
}

.amountInputWithIcon {
  display: flex;
  align-items: center;
  margin: 0;
}
.amountInputWithIcon svg {
  margin-right: 10px;
}
.amountInputWithIcon input {
  flex-grow: 1;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  padding-block: 15px;
}

.btnGroup button,
.btnGroup a button {
  width: 47.5%;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modal {
  border: none;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  padding: 15px;
  width: 100%;
}

.MuiTableRow-root {
  background-color: white;
}

.transDate, .transBalance {
  color: rgb(122, 122, 122);
  font-style: italic;
  font-size: 0.7rem;
}

@media screen and (min-width: 768px) {
  header h3 {
    margin: 0;
  }
  .accounts {
    flex-direction: row;
  }
  .modal {
    border-radius: 15px;
    margin: 5vh auto;
    width: 50%;
    max-height: 90vh;
  }
  .modal::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

@media (prefers-color-scheme: dark) {
  .modal::backdrop {
    background-color: rgba(255, 255, 255, 0.5);
  }
    .MuiTableRow-root {
      background-color: #1e1e1e;
    }
}
